














































































































import {computed, defineComponent, onMounted, useContext, useRouter} from '@nuxtjs/composition-api';
import SfBadge from '/storefrontUI/components/components/atoms/SfBadge/SfBadge.vue';
import SfButton from '/storefrontUI/components/components/atoms/SfButton/SfButton.vue';
import SfTopBar from '/storefrontUI/components/components/organisms/SfTopBar/SfTopBar.vue';
import SfIcon from "/storefrontUI/components/components/atoms/SfIcon/SfIcon.vue";
import SvgImage from '~/components/General/SvgImage.vue';
import { useTopBar } from './useTopBar';
import { useUser } from '~/modules/customer/composables/useUser';
import {useCart, useUiState, useWishlist} from "~/composables";
import {useWishlistStore} from "~/modules/wishlist/store/wishlistStore";

export default defineComponent({
  components: {
    StoreSwitcher: () => import('../StoreSwitcher.vue'),
    SfTopBar,
    SfButton,
    SfIcon,
    SvgImage,
    SfBadge,
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { hasStoresToSelect } = useTopBar();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal } = useUiState();

    const accountIcon = computed(() => (isAuthenticated.value ? 'profile_fill' : 'profile'));

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);
    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });

    return {
      isAuthenticated,
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      handleAccountClick,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasStoresToSelect,
    };
  },
});

